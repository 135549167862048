@import url("https://use.typekit.net/mej6hic.css");
@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  margin: 0;
  font-family: 'neue-haas-grotesk-display';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-family: 'neue-haas-grotesk-display';
}

h5 {
  font-family: 'ballinger-mono';
}

/* ul {
  font-family: 'BALLINGERMONO';
} */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}