#equipment .swiper-button-next:after {
  background-image: url('../assets/images/freccia-r.svg') !important;
  background-size: 60px 60px !important;
  background-repeat: no-repeat !important;
  font-family: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-variant: normal !important;
  line-height: 3 !important;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-image 0.3s ease;
  /* Add transition for a smooth effect */
}

#equipment .swiper-button-prev:after {
  background-image: url('../assets/images/freccia.svg') !important;
  background-size: 60px 60px !important;
  background-repeat: no-repeat !important;
  font-family: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-variant: normal !important;
  line-height: 3 !important;
  position: absolute;
  top: 0;
  left: 0;
  transition: background-image 0.3s ease;
  /* Add transition for a smooth effect */
}

#equipment .swiper-button-prev,
#equipment .swiper-button-next {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-top: -70px;
}

#equipment .swiper-button-next:hover:after {
  background-image: url('../assets/images/frecce-hover-grigio-right.svg') !important;
}

#equipment .swiper-button-prev:hover:after {
  background-image: url('../assets/images/frecce-hover-grigio.svg') !important;
}

@media only screen and (max-width: 768px) {
  #equipment .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 100%) !important;
    /* transform: translateY(100%) !important; */
    /* left: 115px; */
    color: transparent !important;
    display: none !important;
  }

  #equipment .swiper-button-next {
    top: var(--swiper-navigation-top-offset, 100%) !important;
    /* transform: translateY(100%) !important; */
    /* right: 100px; */
    color: transparent !important;
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 115px;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 100px;
    color: transparent !important;
  }
}

@media only screen and (min-width: 1024px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 200px;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 190px;
    color: transparent !important;
  }
}

@media only screen and (min-width: 1280px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 25%;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 24%;
    color: transparent !important;
  }
}

@media only screen and (min-width: 1536px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 405px;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 385px;
    color: transparent !important;
  }
}

@media only screen and (min-width: 1760px) and (max-width: 1920px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 29%;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 26%;
    color: transparent !important;
  }
}

@media only screen and (min-width: 2000px) {
  #equipment .swiper-button-prev {
    transform: translateY(100%) !important;
    left: 28%;
    color: transparent !important;
  }

  #equipment .swiper-button-next {
    transform: translateY(100%) !important;
    right: 28%;
    color: transparent !important;
  }
}