#exercise .swiper-button-prev.swiper-button-disabled,
#exercise .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
}

#exercise .swiper-button-prev,
#exercise .swiper-button-next {
  top: var(--swiper-navigation-top-offset, 100%) !important;
  /* transform: translateY(100%) !important; */
  color: transparent !important;
}

@media only screen and (max-width: 768px) {

  #exercise .swiper-button-prev,
  #exercise .swiper-button-next {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  #exercise .swiper-button-prev {
    left: 370px !important;
  }
}

@media only screen and (min-width: 1024px) {
  #exercise .swiper-button-prev {
    left: 520px !important;
  }
}

@media only screen and (min-width: 1280px) {
  #exercise .swiper-button-prev {
    left: 680px !important;
  }
}

@media only screen and (min-width: 1536px) {
  #exercise .swiper-button-prev {
    left: 840px !important;
  }
}

#exercise .swiper-slide {
  background-color: #ece1ce !important;
  text-align: left !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: start !important;
  align-items: start !important;
  color: #3F3F3E;
}

#exercise .swiper-button-next:after {
  background-image: url('../assets/images/freccia-r.svg') !important;
  background-size: 60px 60px !important;
  background-repeat: no-repeat !important;
  font-family: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-variant: normal !important;
  line-height: 3 !important;
}

#exercise .swiper-button-prev:after {
  background-image: url('../assets/images/freccia.svg') !important;
  background-size: 60px 60px !important;
  background-repeat: no-repeat !important;
  font-family: none !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  font-variant: normal !important;
  line-height: 3 !important;
}

#excercise .swiper-button-prev,
#excercise .swiper-button-next {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-top: -70px;
}

#exercise .swiper-button-next:hover:after {
  background-image: url('../assets/images/frecce-hover-beige-right.svg') !important;
}

#exercise .swiper-button-prev:hover:after {
  background-image: url('../assets/images/frecce-hover-beige.svg') !important;
}